import React, { Suspense, useContext, useReducer } from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client/react";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import Clinet from "./Client";
import Context from "./store/Context";
import Reducer from "./store/Reducer";
import { WebSocketProvider } from "./store/WebSocketContext";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));

const Store = (props) => {
  const initialState = useContext(Context);
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {props.children}
    </Context.Provider>
  );
};

ReactDOM.render(
  <Store>
    <ApolloProvider client={Clinet}>
      <Provider store={configureStore()}>
        <Suspense fallback={<div className="loading" />}>
          <WebSocketProvider>
            <App />
          </WebSocketProvider>
        </Suspense>
      </Provider>
    </ApolloProvider>
  </Store>,
  document.getElementById("root")
);
